<template>
    <div class="c-c-page pc">
        <div class="c-c-page-content">
            <h1>{{$t('companyCulture.title')}}</h1>
            <ul class="reset">
                <li class="left">
                    <!-- 保持600*400宽高比 -->
                    <div class="image">
                        <aspect-ratio ratio="66.67">
                            <img src="@/assets/images/company/u771.jpg"/>
                        </aspect-ratio>
                        <div class="image-shadow">
                            <div class="shadow">
                                <p class="culture-title">01 {{$t('companyCulture.p11')}}</p>
                            </div>
                            <p class="culture-desc">{{$t('companyCulture.p12')}}</p>
                        </div>
                    </div>
                </li>
                <li class="right">
                    <!-- 保持600*400宽高比 -->
                    <div class="image">
                        <aspect-ratio ratio="66.67">
                            <img v-bind:src="$resources.autoTransform($resources.Image.static['u773.jpg'])"/>
                        </aspect-ratio>
                        <div class="image-shadow">
                            <div class="shadow">
                                <p class="culture-title">02 {{$t('companyCulture.p21')}}</p>
                            </div>
                            <p class="culture-desc">{{$t('companyCulture.p22')}}</p>
                        </div>
                    </div>
                </li>
                <li class="left">
                    <!-- 保持600*400宽高比 -->
                    <div class="image">
                        <aspect-ratio ratio="66.67">
                            <img v-bind:src="$resources.autoTransform($resources.Image.static['u776.jpg'])"/>
                        </aspect-ratio>
                        <div class="image-shadow">
                            <div class="shadow">
                                <p class="culture-title">03 {{$t('companyCulture.p31')}}</p>
                            </div>
                            <p class="culture-desc">{{$t('companyCulture.p32')}}</p>
                        </div>
                    </div>
                </li>
                <li class="right">
                    <!-- 保持600*400宽高比 -->
                    <div class="image">
                        <aspect-ratio ratio="66.67">
                            <img src="@/assets/images/company/u777.jpg"/>
                        </aspect-ratio>
                        <div class="image-shadow">
                            <div class="shadow">
                                <p class="culture-title">04 {{$t('companyCulture.p41')}}</p>
                            </div>
                            <p class="culture-desc">{{$t('companyCulture.p42')}}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>


<script>

  import AspectRatio from '../../components/AspectRatio';

  export default {
    components: {
      AspectRatio,
      'aspect-ratio': AspectRatio,
    },

    data() {
      return {};
    }
  }
</script>


<style lang="scss">

    .c-c-page.pc {
        background-color: var(--white);

        img {
            width: 100%;
            height: 100%;
        }

        .c-c-page-content {
            width: 100%;
            max-width: 1128px;
            margin: 0 auto;
            padding-top: 80px;
            padding-bottom: 100px;

            @media screen and (max-width: 768px) {
                padding-top: 20px;
                padding-bottom: 30px;
            }

            h1 {
                margin: 0;
                font-size: 70px;
                line-height: 100px;
                text-align: center;

                @media screen and (max-width: 768px) {
                    font-size: 35px;
                    line-height: 80px;
                }
            }

            ul {
                margin-top: 40px;

                @media screen and (max-width: 768px) {
                    margin: 0 15px 0 15px;
                }

                li {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 100px;

                    @media screen and (max-width: 768px) {
                        margin-top: 20px;
                    }

                    &:not(:first-child) {
                        margin-top: 60px;

                        @media screen and (max-width: 768px) {
                            margin-top: 0px;
                        }
                    }

                    .image {
                        width: 50%;
                        min-width: 400px;
                        max-width: 600px;
                        height: 100%;
                        position: relative;

                        & > * {
                            z-index: 10;
                        }

                        .image-shadow {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            z-index: 5;

                            .shadow {
                                display: block;
                                width: 100%;
                                height: 100%;
                                background-color: rgba(1, 1, 1, .3);
                                position: relative;

                                .culture-title {
                                    margin: 0;
                                    position: absolute;
                                    color: var(--blue);
                                    font-size: 28px;
                                    font-weight: 600;
                                }
                            }
                            .culture-desc {
                                margin: 0;
                                padding-top: 10px;
                                color: var(--black);
                                font-size: 16px;
                                font-weight: bold;
                                position: absolute;
                            }
                        }
                    }

                }
                li.left {
                    .image {
                        -webkit-transform: translateX(-50%);
                        -moz-transform: translateX(-50%);
                        -ms-transform: translateX(-50%);
                        -o-transform: translateX(-50%);
                        transform: translateX(-50%);

                        .image-shadow {
                            right: -25px;
                            bottom: -25px;

                            .shadow {
                                .culture-title {
                                    padding: 0 0 0 20px;
                                    bottom: 0;
                                    right: 0;
                                    -webkit-transform: translateX(100%);
                                    -moz-transform: translateX(100%);
                                    -ms-transform: translateX(100%);
                                    -o-transform: translateX(100%);
                                    transform: translateX(100%);
                                }
                            }
                            .culture-desc {
                                bottom: 0;
                                right: 0;
                                -webkit-transform: translate(50%, 100%);
                                -moz-transform: translate(50%, 100%);
                                -ms-transform: translate(50%, 100%);
                                -o-transform: translate(50%, 100%);
                                transform: translate(50%, 100%);
                            }
                        }
                    }
                }
                li.right {
                    .image {
                        -webkit-transform: translateX(50%);
                        -moz-transform: translateX(50%);
                        -ms-transform: translateX(50%);
                        -o-transform: translateX(50%);
                        transform: translateX(50%);

                        .image-shadow {
                            left: -25px;
                            bottom: -25px;

                            .shadow {
                                .culture-title {
                                    padding: 0 20px 0 0;
                                    bottom: 0;
                                    left: 0;
                                    -webkit-transform: translateX(-100%);
                                    -moz-transform: translateX(-100%);
                                    -ms-transform: translateX(-100%);
                                    -o-transform: translateX(-100%);
                                    transform: translateX(-100%);
                                }
                            }
                            .culture-desc {
                                -webkit-transform: translateX(-50%);
                                -moz-transform: translateX(-50%);
                                -ms-transform: translateX(-50%);
                                -o-transform: translateX(-50%);
                                transform: translateX(-50%);
                            }
                        }
                    }
                }
                li:not(:first-child) {
                    margin-top: 150px;
                }
            }
        }
    }

</style>
